
































import { namespace } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DealStoreActions, DealStoreGetters } from '@/store/deal.store';
import Deal from '@/models/Deal.model';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';
import AxiosErrorHandlerMixin from '@/misc/AxiosErrorHandler.mixin';

const DealsStore = namespace('deals');

@Component({
  components: {
    DealListComponent: () => import(
      /* webpackChunkName: "DealListComponent" */
      '@/components/DealList.component.vue'
    ),
    EditDealComponent: () => import(
      /* webpackChunkName: "EditDealComponent" */
      '@/components/EditDeal.component.vue'
    )
  }
})
export default class DealsView extends mixins(BaseMixin, AxiosErrorHandlerMixin) {
  @Prop({ required: true })
  public storeId!: string;

  @DealsStore.Action(DealStoreActions.GET_BY_STORE)
  private getDealsByStoreAction!: (id: string) => Promise<Deal[]>;

  @DealsStore.Getter(DealStoreGetters.ACTIVE_DEALS)
  private activeDeals!: Deal[];

  @DealsStore.Getter(DealStoreGetters.INACTIVE_DEALS)
  private inactiveDeals!: Deal[];

  private isLoading: boolean = false;

  private search: string = '';

  async created() {
    try {
      this.isLoading = true;
      await this.getDealsByStoreAction(this.storeId);
    } catch (e) {
      this.handleAxiosError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
